//define constant for campaign option
const CAMPAIGN_OPTION_HALF = "half";
const CAMPAIGN_OPTION_FULL = "full";
const CAMPAIGN_OPTION_SPECIAL = "special";
const CAMPAIGN_OPTION_INHOUSE = "inhouse";
const CAMPAIGN_OPTION_PREMIUM = "premium";
//define constant for campaign premium option
const PREMIUM_OPTION_ARTICLE = "article";
const PREMIUM_OPTION_POPUP = "popup";
//define constant for screen
const SCREEN_DESKTOP = "desktop";
const SCREEN_MOBILE = "mobile";
//define undefined constant
const UNDEFINED = "undefined";

function check_blank_banner_image(img_id) {
	leaderboard_img = document.querySelector(
		"img[data-img-randome-id='" + img_id + "']"
	);
	if (leaderboard_img.width == 1 && leaderboard_img.height == 1) {
		leaderboard_img.style.visibility = "hidden";
	} else {
		leaderboard_img.style.visibility = "visible";
	}
}

function leaderboard_template(ad_zone) {
	random = Math.floor(Math.random() * 99999999999);
	random_image_id = "leaderboard_ads_image_id_" + random + "_" + ad_zone;
	return (
		"<a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=" +
		random +
		"&amp;cb=" +
		random +
		"' target='_blank'><img data-img-randome-id=" +
		random_image_id +
		" src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid=" +
		ad_zone +
		"&amp;cb=" +
		random +
		"&amp;n=" +
		random +
		"&amp;' border='0' alt='' onload='check_blank_banner_image(\"" +
		random_image_id +
		"\")' /></a>"
	);
}

function recreate_leaderboard_ads(ele_id, ad_zone) {
	leaderboard_element = document.getElementById(ele_id);

	if (typeof leaderboard_element != UNDEFINED && leaderboard_element && typeof ad_zone != UNDEFINED && ad_zone) {
		leaderboard_element.setAttribute("zone", ad_zone);
		leaderboard_element.innerHTML = leaderboard_template(ad_zone);
	}
}

function recreate_leaderboard_banner(
	mobile_leaderboard_ad_zone,
	desktop_leaderboard_ad_zone
) {
	if (CHANGING_LEADERBOARD_DURATION > 0) {
		setInterval(function () {
			recreate_leaderboard_ads("ad_zone_01", desktop_leaderboard_ad_zone);
			recreate_leaderboard_ads("ad_zone_04", mobile_leaderboard_ad_zone);
		}, CHANGING_LEADERBOARD_DURATION || 30000);
	}
}

SBAdvertisement = function (options) {
	this.init(options);
};

$.extend(SBAdvertisement.prototype, {
	default: {
		app_code: null,
		default_ads: null,
		gateway_banners: null,
		menu_ads: null,
		tag_ads: null,
		is_landing_page: false,
		is_menu_page: false,
		is_tag_page: false,
		is_article_page: false,
		curent_category: null,
		curent_tag: null,
		curent_tags: [],
		current_post: null,
		no_changing_leaderboard: false,
	},

	// object variables
	options: new Object(),

	init: function (options) {
		// do initialization here
		this.options = this.merge(this.default, options);
	},

	merge: function (obj, src) {
		for (var key in src) {
			if (src.hasOwnProperty(key)) obj[key] = src[key];
		}
		return obj;
	},

	consoleOptions: function () {},

	is_expired: function (date) {
		var now = new moment().valueOf();
		var date = new moment.utc(date).valueOf();
		return now >= date;
	},

	replace_sponsor_ads: function (ads) {
		var default_ads = this.options.default_ads;
		if (default_ads.length < 3) {
			return;
		}

		var campaign_date = ads[0].campaign.end_date;

		if (this.is_expired(campaign_date)) {
			return;
		}

		switch (ads[0].campaign.option) {
			case CAMPAIGN_OPTION_HALF:
				default_ads[2] = ads[0];
				break;

			case CAMPAIGN_OPTION_FULL:
				default_ads[1] = ads[0];
				default_ads[2] = ads[0];
				break;

			case CAMPAIGN_OPTION_SPECIAL:
				this.options.no_changing_leaderboard = true;
				default_ads[0] = ads[0];
				default_ads[1] = ads[1];
				default_ads[2] = ads[1];
				default_ads[3] = ads[0];
				default_ads[9] = ads[1];
				default_ads[10] = ads[1];
				this.replace_special_gateway_banners();
				this.generate_premium_campaign(ads);
				break;

			case CAMPAIGN_OPTION_INHOUSE:
				this.options.no_changing_leaderboard = true;
				default_ads[0] = ads[0];
				default_ads[1] = ads[1];
				default_ads[2] = ads[2];
				default_ads[3] = ads[3];
				this.generate_premium_campaign(ads);
				break;

			case CAMPAIGN_OPTION_PREMIUM:
				var premium_option = ads[0].campaign.param.premium_option;
				var is_underlay = ads[0].campaign.param.is_underlay;
				if (premium_option == PREMIUM_OPTION_POPUP) {
					var popup_option = ads[0].campaign.param.popup_option;
				} else {
					var popup_option = "";
				}

				if (premium_option == PREMIUM_OPTION_ARTICLE) {
					if (is_underlay) {
						default_ads[4] = ads[0];
					} else {
						default_ads[6] = ads[0];
					}
				} else if (premium_option == PREMIUM_OPTION_POPUP) {
					if (popup_option == SCREEN_DESKTOP) {
						default_ads[7] = ads[0];
					} else if (popup_option == SCREEN_MOBILE) {
						default_ads[8] = ads[0];
					} else {
						default_ads[7] = ads[0];
						default_ads[8] = ads[1];
					}
				} else {
					if (is_underlay) {
						default_ads[4] = ads[0];
					} else {
						default_ads[6] = ads[0];
					}

					if (popup_option == SCREEN_DESKTOP) {
						default_ads[7] = ads[1];
					} else if (popup_option == SCREEN_MOBILE) {
						default_ads[8] = ads[1];
					} else {
						default_ads[7] = ads[1];
						default_ads[8] = ads[2];
					}
				}
				break;
		}
	},

	replace_special_gateway_banners: function () {
		var gateway_banners = this.options.gateway_banners;

		$.each(gateway_banners, function (index, banner) {
			if (typeof banner !== UNDEFINED && banner.campaign.option != "special") {
				gateway_banners.splice(index, 1);
			}
		});
	},

	handle_menu_page: function () {
		var category = this.options.curent_category;
		if (!category) {
			return;
		}

		this.handle_menu_sponsor(category);
		this.handle_exclude_menu();
	},

	handle_menu_sponsor: function (menu) {
		var menu_id = menu.id;
		var menu_ads = this.options.menu_ads;

		if (typeof menu_ads[menu_id] === UNDEFINED) {
			return false;
		}
		menu_ad = menu_ads[menu_id];
		this.replace_sponsor_ads(menu_ad);
		return true;
	},

	handle_tag_page: function () {
		var tag = this.options.curent_tag;
		if (!tag) {
			return;
		}
		this.options.curent_tags[0] = tag;
		this.handle_tag_sponsor(tag);
		this.handle_exclude_tag();
	},

	handle_tag_sponsor: function (tag) {
		var tag_ads = this.options.tag_ads;
		if (typeof tag_ads[tag.id] === UNDEFINED) {
			return false;
		}
		tag_ad = tag_ads[tag.id];

		this.replace_sponsor_ads(tag_ad);
		return true;
	},

	handle_article_page: function () {
		var post = this.options.current_post;
		if (!post) {
			return;
		}

		var tags = post.tags;
		var found_ad = false;
		var that = this;

		if (tags.length > 0) {
			this.options.curent_tags = tags;
			$.each(tags, function (index, tag) {
				found_ad = that.handle_tag_sponsor(tag);
				if (found_ad) {
					// Stop loop
					return false;
				}
			});
		}

		this.handle_exclude_content();
		this.handle_exclude_tags();

		if (found_ad) {
			return;
		}

		var app_code = this.options.app_code;

		var menu = post.menus[app_code];

		this.options.curent_category = menu;
		this.handle_menu_sponsor(menu);
		this.handle_exclude_menu();
	},

	ad_zone_handler: function () {
		if (this.options.is_menu_page) {
			this.handle_menu_page();
		} else if (this.options.is_tag_page) {
			this.handle_tag_page();
		} else if (this.options.is_article_page) {
			this.handle_article_page();
		}
	},

	display: function () {
		this.ad_zone_handler();

		if (!this.options.no_changing_leaderboard) {
			desktop_leaderboard_ad_zone = this.options.default_ads[0].zone;
			mobile_leaderboard_ad_zone = this.options.default_ads[3].zone;

			// create leaderboard ads after document ready
			document.addEventListener(
				"DOMContentLoaded",
				recreate_leaderboard_banner(
					mobile_leaderboard_ad_zone,
					desktop_leaderboard_ad_zone
				),
				false
			);
		}

		for (i = 0; i < this.options.default_ads.length; i++) {
			var id = i + 1;
			if (id < 10) {
				id = "0" + id;
			}
			this.create_ads("ad_zone_" + id, this.options.default_ads[i].zone);
		}

		if (this.options.gateway_banners.length > 0) {
			for (i = 0; i < this.options.gateway_banners.length; i++) {
				var id = i + 1;
				if (id < 10) {
					id = "0" + id;
				}
				this.create_ads(
					"gateway_banner_" + id,
					this.options.gateway_banners[i].campaign.gateway_banner
				);
			}
		} else {
			$(".gateway-banner").remove();
		}
	},

	create_ads: function (ele_id, ads) {
		if (ele_id == "ad_zone_08" || ele_id == "ad_zone_09") {
			var element = $("body").find("#" + ele_id + "");
		} else {
			var element = $("body").find("#" + ele_id + ":visible");
		}

		if (typeof ads != UNDEFINED && ads) {
			element.attr("zone", ads).html(this.template(ads));
		}
	},

	template: function (ad_zone) {
		var params = new URLSearchParams(window.location.search);
		var config_params = params.get("config");
		if (config_params != null) {
			var configs = config_params.split(",");
			if ($.inArray("ads_async", configs) != -1) {
				var random = Math.floor(Math.random() * 99999999999);
				return (
					"<a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=" +
					random +
					"&amp;cb=" +
					random +
					"' target='_blank'><img src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid=" +
					ad_zone +
					"&amp;cb=" +
					random +
					"&amp;n=" +
					random +
					"&amp;' border='0' alt='' /></a>"
				);
			}
		}
		return (
			'<ins data-revive-zoneid="' +
			ad_zone +
			'"  data-revive-id="4b9ca95baee4430ad25d6d07da6464a5"></ins><script async src="//ads.sabay.com/openx/www/delivery/asyncjs.php">'
		);
	},

	handle_exclude_menu: function () {
		var that = this;
		var gateway_banners = [];
		if (that.options.gateway_banners.length > 0) {
			for (var i = 0; i < that.options.gateway_banners.length; i++) {
				var gateway_banner = that.options.gateway_banners[i];
				var curent_menu_id = that.options.curent_category.id;
				if (typeof gateway_banner != UNDEFINED) {
					var exclude_menu_ids = gateway_banner.campaign.param.exclude_menu_ids;
					if (!exclude_menu_ids.includes(curent_menu_id.toString())) {
						gateway_banners.push(gateway_banner);
					}
				}
			}
		}

		this.options.gateway_banners = gateway_banners;
	},

	handle_exclude_tag: function () {
		var that = this;
		var gateway_banners = [];

		if (that.options.gateway_banners.length > 0) {
			for (var i = 0; i < that.options.gateway_banners.length; i++) {
				var gateway_banner = that.options.gateway_banners[i];
				var curent_tag_id = that.options.curent_tags[0].id;
				if (typeof gateway_banner != UNDEFINED) {
					var exclude_tag_ids = gateway_banner.campaign.param.exclude_tag_ids;
					if (!exclude_tag_ids.includes(curent_tag_id.toString())) {
						gateway_banners.push(gateway_banner);
					}
				}
			}
		}

		this.options.gateway_banners = gateway_banners;
	},

	handle_exclude_tags: function () {
		var is_sponsor = false;
		var that = this;
		var gateway_banners = [];
		var tag_ids = [];
		if (that.options.gateway_banners.length > 0) {
			for (var i = 0; i < that.options.gateway_banners.length; i++) {
				var gateway_banner = that.options.gateway_banners[i];
				if (typeof gateway_banner != UNDEFINED) {
					var exclude_tag_ids = gateway_banner.campaign.param.exclude_tag_ids;
					for (var j = 0; j < that.options.curent_tags.length; j++) {
						var tag = that.options.curent_tags[j];
						var found_ads = that.handle_tag_sponsor(tag);
						if (found_ads) {
							is_sponsor = true;
							if (!exclude_tag_ids.includes(tag.id.toString())) {
								gateway_banners.push(gateway_banner);
							}
						}
					}
				}
			}
		}
		if (is_sponsor) this.options.gateway_banners = gateway_banners;
		else this.options.gateway_banners = this.options.gateway_banners;
	},

	handle_exclude_content: function () {
		var that = this;

		$.each(that.options.gateway_banners, function (index, gateway_banner) {
			if (typeof gateway_banner != UNDEFINED) {
				var exclude_content_ids =
					gateway_banner.campaign.param.exclude_content_ids;
				var curent_content_id = that.options.current_post.id;

				if (exclude_content_ids.includes(curent_content_id.toString())) {
					that.options.gateway_banners.splice(index, 1);
				}
			}
		});

		this.options.gateway_banners = that.options.gateway_banners;
	},

	generate_premium_campaign: function (ads) {
		for (i = 0; i < ads.length; i++) {
			if (ads[i].campaign.option == CAMPAIGN_OPTION_PREMIUM) {
				var premium_option = ads[i].campaign.param.premium_option;
				var is_underlay = ads[i].campaign.param.is_underlay;

				if (premium_option == SCREEN_DESKTOP) {
					var popup_option = ads[i].campaign.param.popup_option;
				} else {
					var popup_option = "";
				}

				if (premium_option == PREMIUM_OPTION_ARTICLE) {
					if (is_underlay) {
						default_ads[4] = ads[i];
					} else {
						default_ads[6] = ads[i];
					}
				} else if (premium_option == PREMIUM_OPTION_POPUP) {
					if (popup_option == SCREEN_DESKTOP) {
						default_ads[7] = ads[i];
					} else if (popup_option == SCREEN_MOBILE) {
						default_ads[8] = ads[i];
					} else {
						default_ads[7] = ads[i];
						default_ads[8] = ads[i + 1];
					}
				} else {
					if (is_underlay) {
						default_ads[4] = ads[i];
					} else {
						default_ads[6] = ads[i];
					}

					if (popup_option == SCREEN_DESKTOP) {
						default_ads[7] = ads[i + 1];
					} else if (popup_option == SCREEN_MOBILE) {
						default_ads[8] = ads[i + 1];
					} else {
						default_ads[7] = ads[i + 1];
						default_ads[8] = ads[i + 2];
					}
				}
				break;
			}
		}
	},
});

$("#ads-close").click(function () {
	$(this).parent().remove();
	return false;
});
