var SMALL_WIDTH = 767;
// Smart menu
window.onresize = navigationResize;
$( document ).ready( function () {
  setTimeout(function(){
    navigationResize();
  }, 500);
});

function navigationResize() {
  var width = $(document).width();
  if(width > SMALL_WIDTH) {
    $('nav#menu .nav li.more').before($('#overflow > li'));

    var $navItemMore = $('nav#menu .nav > li.more'),
        $navItems = $('nav#menu .nav > li:not(.more)'),
        navItemMoreWidth = navItemWidth = $navItemMore.width(),
        windowWidth = $('nav#menu .nav').width() - 20,
        navItemMoreLeft,
        offset,
        navOverflowWidth;

    $navItems.each(function() {
      navItemWidth += $(this).width();
    });

    navItemWidth > windowWidth ? $navItemMore.show() : $navItemMore.hide();

    while (navItemWidth > windowWidth) {
      navItemWidth -= $navItems.last().width();
      $navItems.last().prependTo('nav#menu #overflow');
      $navItems.splice(-1,1);
    }

    navItemMoreLeft = $('nav#menu .nav li.more').offset().left;
    navOverflowWidth = $('nav#menu #overflow').width();
    offset = navItemMoreLeft + navItemMoreWidth - navOverflowWidth;

    // $('nav#menu #overflow').css({
    //   'left': offset
    // });
  }
}
// End smart menu